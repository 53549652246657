.page-body {
  background-color: white;
}

.department-card {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the content horizontally */
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(122, 132, 168, 0.35) !important;
}

.department-title {
  font-weight: bold;
  font-size: 38px;
  color: #194995;
  margin-bottom: 43px;
  margin-top: 90px;
}
.cards-container {
  display: flex;
  justify-content: center;
  gap: 90px;
  width: 100%;
  margin-bottom: 90px;
}

.cards {
  border-radius: 9px;
  padding: 20px;
  width: 320px;
  height: 218px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #194995, #194995); /* Colorful gradient */
  color: white;
  font-size: 2.2em;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a slight shadow for better visual effect */
  cursor: pointer; /* Show hand cursor on hover */
}

.cards:hover {
  transform: scale(1.1); /* Scale up the card */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Enhance the shadow */
}

.back-icon {
  cursor: pointer; /* Show hand cursor on hover */
  margin-right: 10px; /* Add space between icon and title */
  color: #194995; /* Make the icon color consistent with the title */
}

.person-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(135deg, #194995, #194995);
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 20px rgba(122, 132, 168, 0.35) !important;
  width: 250px;
  text-align: center;
  color: white;
}

.company-name {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.person-photo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.person-details {
  margin-top: 10px;
}

.person-name {
  font-size: 18px;
  font-weight: bold;
}

.person-designation {
  font-size: 16px;
  color: #ffffffb3;
}

.parent-card {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a slight shadow for better visual effect */
  overflow-y: auto; /* Enable vertical scrolling if needed */
  margin-top: 20px; /* Space from the top */
  width: 100%; /* Full width */
  position: relative; /* Ensure the icon can be positioned absolutely */
  align-items: flex-start; /* Align items to the start (top) */
}

.header-container {
  display: flex;
  align-items: center; /* Align the back icon and department title on the same line */
  margin-bottom: 20px;
  width: 100%; /* Make sure it takes up full width */
}

.header-container h2 {
  font-size: 38px;
  color: #194995;
  margin: 0;
}

.persons-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; /* Align items to the start */
  gap: 20px;
  width: 100%; /* Full width of parent card */
  margin-top: 10px; /* Space from the top of the parent card */
}

.person-details-card {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.card-per {
  width: 100%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a card shadow */
  overflow: hidden; /* Ensure no overflow issues */
}

.card-header-per {
  display: flex;
  align-items: center;
  padding: 20px;
  background-color: #f9f9f9;
  border-bottom: 1px solid #ddd;
}

.card-header-per .person-name {
  margin-left: 10px; /* Space between the icon and name */
  font-size: 1.5em;
  color: #194995;
}

.card-body {
  padding: 20px;
}

.person-photo-large {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.tabs {
  display: flex;
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
}

.tab-item {
  padding: 10px 20px;
  cursor: pointer;
  border-bottom: 3px solid transparent;
  color: #555;
  transition: all 0.3s;
}

.tab-item.active {
  border-bottom: 3px solid #194995;
  color: #194995;
}

.tab-item:hover {
  color: #194995;
}
.tab-contentt {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
}


.section {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex: 1 1 calc(50% - 20px);
  margin-bottom: 20px;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.section-title {
  font-size: 18px;
  font-weight: bold;
  color: #194995;
}

.edit-link {
  color: #194995;
  font-weight: bold;
  text-decoration: none;
}

.edit-link:hover {
  text-decoration: underline;
}

.user-info {
  flex-grow: 1;
}

.reset-password-button {
  background-color: #194995;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.timeoff-balances {
  display: flex;
  justify-content: space-between;
}

.balance-item {
  flex: 1 1 calc(50% - 10px);
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 10px;
  text-align: center;
}

.balance-item h4 {
  font-size: 16px;
  color: #194995;
  margin-bottom: 10px;
}

@media (min-width: 1200px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 100% !important;
  }
}

.bank-accounts-card {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(122, 132, 168, 0.35) !important;
  margin-bottom: 20px;
}

.bank-accounts-card .section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.bank-accounts-card .section-title {
  font-size: 24px;
  font-weight: bold;
  color: #194995;
}

.bank-accounts-card .add-button {
  background-color: #194995;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.bank-accounts-card .bank-accounts-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.bank-accounts-card .bank-accounts-table th,
.bank-accounts-card .bank-accounts-table td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.bank-accounts-card .bank-accounts-table th {
  color: #194995;
  font-weight: bold;
}

.bank-accounts-card .bank-accounts-table td {
  color: #333;
}

.bank-accounts-card .edit-button {
  background-color: #194995;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}
.family-info-card {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(122, 132, 168, 0.35) !important;
  margin-bottom: 20px;
}

.family-info-card .section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.family-info-card .section-title {
  font-size: 24px;
  font-weight: bold;
  color: #194995;
}

.family-info-card .add-button {
  background-color: #194995;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.family-info-card .family-info-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.family-info-card .family-info-table th,
.family-info-card .family-info-table td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.family-info-card .family-info-table th {
  color: #194995;
  font-weight: bold;
}

.family-info-card .family-info-table td {
  color: #333;
}

.family-info-card .edit-button {
  background-color: #194995;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.relation-icon {
  display: flex;
  align-items: center;
}

.relation-icon i {
  font-size: 24px;
  margin-right: 10px;
  color: #194995;
}

.relation-icon span {
  font-size: 16px;
  color: #194995;
}

.accordion {
  margin-top: 20px;
}

.accordion-item {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 16px;
  color: #333;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accordion-item:hover {
  background-color: #e9e9e9;
}

.accordion-content {
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 10px;
  margin-top: -10px;
  margin-bottom: 10px;
}

.accordion-content p {
  margin: 0;
  font-size: 14px;
  color: #666;
}

.accordion-item:after {
  content: '\25BC'; /* Unicode character for down arrow */
  font-size: 12px;
  color: #666;
  transform: rotate(0deg);
  transition: transform 0.3s;
}

.accordion-item.open:after {
  transform: rotate(180deg); /* Rotate arrow when the section is open */
}

.competencies-card {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(122, 132, 168, 0.35) !important;
  margin-bottom: 20px;
}

.competencies-card .section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.competencies-card .section-title {
  font-size: 24px;
  font-weight: bold;
  color: #194995;
}

.competencies-card .add-button {
  background-color: #194995;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.competencies-content {
  text-align: center;
  color: #666;
  font-size: 16px;
  margin-top: 50px;
}

.insurance-card {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(122, 132, 168, 0.35) !important;
  margin-bottom: 20px;
}

.insurance-card .section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.insurance-card .section-title {
  font-size: 24px;
  font-weight: bold;
  color: #194995;
}

.insurance-card .add-button {
  background-color: #194995;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.insurance-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.insurance-table th,
.insurance-table td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.insurance-table th {
  color: #194995;
  font-weight: bold;
}

.insurance-table td {
  color: #333;
}

.insurance-table .edit-button {
  background-color: #194995;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.insurance-table .inactive-row {
  background-color: #f28b82;
  color: white;
}

.air-ticket-card {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(122, 132, 168, 0.35) !important;
  margin-bottom: 20px;
}

.air-ticket-card .section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.air-ticket-card .section-title {
  font-size: 24px;
  font-weight: bold;
  color: #194995;
}

.air-ticket-card .add-button {
  background-color: #194995;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.air-ticket-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.air-ticket-table th,
.air-ticket-table td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.air-ticket-table th {
  color: #194995;
  font-weight: bold;
}

.air-ticket-table td {
  color: #333;
}
.timeoff-card {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(122, 132, 168, 0.35) !important;
  margin-bottom: 20px;
}

.timeoff-card .section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.timeoff-card .section-title {
  font-size: 24px;
  font-weight: bold;
  color: #194995;
}

.timeoff-card .add-button {
  background-color: #194995;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.timeoff-card .toolbar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}



.timeoff-card .toolbar-buttons {
  display: flex;
  align-items: center;
}
.toolbar-sear{
  padding: 5px 15px;
  color: #fff;
  border-radius: 5px;
  margin-right: 15px;
}
.timeoff-card .toolbar-sear {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.assets-card .toolbar-sear {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.contracts-card .toolbar-sear {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.timeoff-card .export-button {
  background-color: #f3f3f3;
  border: 1px solid #ddd;
  padding: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.timeoff-card .results-per-page {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.timeoff-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.timeoff-table th,
.timeoff-table td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.timeoff-table th {
  color: #194995;
  font-weight: bold;
}

.timeoff-table td {
  color: #333;
}

.timeoff-table .status {
  padding: 5px 10px;
  border-radius: 5px;
  color: white;
}

.timeoff-table .status.active {
  background-color: #34c759;
}

.timeoff-table .action-button {
  background-color: #f3f3f3;
  border: none;
  padding: 5px 10px;
  margin-right: 10px;
  cursor: pointer;
}

.timeoff-table .icon-edit,
.timeoff-table .icon-trash,
.timeoff-table .icon-more-horizontal {
  margin-right: 10px;
  cursor: pointer;
  color: #666;
}
.assets-card {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(122, 132, 168, 0.35) !important;
  margin-bottom: 20px;
}

.assets-card .section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.assets-card .section-title {
  font-size: 24px;
  font-weight: bold;
  color: #194995;
}

.assets-card .add-button {
  background-color: #194995;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.assets-card .toolbar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}



.assets-card .toolbar-buttons {
  display: flex;
  align-items: center;
}

.assets-card .results-per-page {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.assets-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.assets-table th,
.assets-table td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.assets-table th {
  color: #194995;
  font-weight: bold;
}

.assets-table td {
  color: #333;
}

.assets-table .edit-button {
  background-color: #194995;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}
.projects-card {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(122, 132, 168, 0.35) !important;
  margin-bottom: 20px;
}

.projects-card .section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.projects-card .section-title {
  font-size: 24px;
  font-weight: bold;
  color: #194995;
}

.projects-card .add-button {
  background-color: #194995;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.projects-content {
  text-align: center;
  color: #666;
  font-size: 16px;
  margin-top: 50px;
}
.contracts-card {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(122, 132, 168, 0.35) !important;
  margin-bottom: 20px;
}

.contracts-card .section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.contracts-card .section-title {
  font-size: 24px;
  font-weight: bold;
  color: #194995;
}

.contracts-card .add-button {
  background-color: #194995;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.contracts-card .toolbar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}


.contracts-card .toolbar-buttons {
  display: flex;
  align-items: center;
}

.contracts-card .results-per-page {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.contracts-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.contracts-table th,
.contracts-table td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.contracts-table th {
  color: #194995;
  font-weight: bold;
}

.contracts-table td {
  color: #333;
}

.contract-name {
  display: flex;
  align-items: center;
}

.contract-name i {
  color: #34c759;
  margin-right: 10px;
}

.off-boarding-card {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(122, 132, 168, 0.35) !important;
  margin-bottom: 20px;
}

.off-boarding-card .section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.off-boarding-card .section-title {
  font-size: 24px;
  font-weight: bold;
  color: #194995;
}

.off-boarding-steps {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.off-boarding-steps .step {
  padding: 10px 20px;
  border-radius: 5px;
  margin-right: 10px;
  color: #194995;
  font-weight: bold;
}

.off-boarding-steps .step.active {
  background-color: #194995;
  color: white;
}

.off-boarding-steps .step + .step::before {
  content: '>';
  color: #194995;
  margin-right: 10px;
}



.boarding-template {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  width: 60%;
}

.skip-button {
  background-color: #333;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}


.timeoff-card .search-input {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  width: 200px;
}

.assets-card .search-input {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  width: 200px;
}

.contracts-card .search-input {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  width: 200px;
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add-button {
  margin-left: auto; /* Ensures the button is pushed to the far right */
}

.back-icon {
  cursor: pointer;
}

.person-name {
  margin: 0;
  flex-grow: 1;
  text-align: center;
}

.delete-button {
  margin-left: auto;
}
/* Container for scrollable tables */
.scrollable-table-container {
  max-height: 400px; /* Adjust the height as needed */
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: auto; /* Enable horizontal scrolling */
  display: block; /* Ensure container behaves as a block-level element */
}

/* Table styles */
.scrollable-table {
  min-width: 800px; /* Adjust as needed to prevent word clumping */
}

/* Optional: Style for the table header and body */
.scrollable-table thead th {
  white-space: nowrap; /* Prevent text wrapping in header cells */
}

.scrollable-table tbody td {
  white-space: nowrap; /* Prevent text wrapping in body cells */
}

/* Optional: Style for table borders and spacing */
.scrollable-table th, .scrollable-table td {
  border: 1px solid #dee2e6; /* Light border color */
  padding: 8px; /* Padding for cells */
}

/* Optional: Style for table rows */
.scrollable-table tbody tr:nth-of-type(even) {
  background-color: #f8f9fa; /* Light grey background for even rows */
}

.scrollable-table tbody tr:hover {
  background-color: #e9ecef; /* Hover effect */
}
/* Custom scrollbar for Webkit browsers */
.scrollable-table-container::-webkit-scrollbar {
  width: 4px; /* Adjust the width of the scrollbar */
  height: 4px; /* Adjust the height of the horizontal scrollbar (if needed) */
}

/* Style the track (part of the scrollbar not being dragged) */
.scrollable-table-container::-webkit-scrollbar-track {
  background-color: #f0f0f0; /* Light gray color for the track */
  border-radius: 10px; /* Rounded corners for the track */
}

/* Style the handle (the draggable part of the scrollbar) */
.scrollable-table-container::-webkit-scrollbar-thumb {
  background-color: #888; /* Gray color for the handle */
  border-radius: 10px; /* Rounded corners for the handle */
}

/* Style the handle on hover */
.scrollable-table-container::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Darker gray color for the handle on hover */
}

.person-img-medium {
  width: 150px; /* Adjust the size as needed */
  height: 150px; /* Make sure it's the same as width to form a circle */
  /* border-radius: 50%;  */
  object-fit: cover; /* Ensures the image fills the circle without distortion */
  display: block; /* Remove any spacing below the image */
}
/* Main container styling */
.tab-contentt {
  padding: 20px;
  background-color: #f8f9fa;
}

/* Section styling */
.section.documents-card {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Header styling */
.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #e0e0e0;
  padding-bottom: 15px;
  margin-bottom: 20px;
}

.section-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #2c3e50;
}

/* Add button */
.add-button {
  background-color: #007bff !important;
  border-radius: 8px;
  padding: 10px 20px;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
}

.add-button:hover {
  background-color: #0056b3 !important;
}

/* Document card container */
.document-card {
  background: #ffffff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
  width: 100%;
}

.document-card:hover {
  transform: translateY(-3px);
}

/* Document title */
.document-card h5 {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
}

/* Text styling */
.document-card p {
  margin-bottom: 8px;
  color: #555;
  font-size: 0.95rem;
}

.document-card strong {
  color: #007bff;
}

/* View Document Button */
.document-card .btn-success {
  background-color: #28a745;
  border-radius: 8px;
  padding: 8px 16px;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
}

.document-card .btn-success:hover {
  background-color: #218838;
}

/* Responsive Design */
@media (max-width: 768px) {
  .section-header {
    flex-direction: column;
    text-align: center;
  }

  .add-button {
    margin-top: 10px;
    width: 100%;
  }

  .document-card {
    padding: 15px;
  }
}
.department-container {
  text-align: center;
  padding: 40px;
  max-width: 900px;
  margin: 0 auto;
}



.department-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  gap: 20px;
  justify-content: center;
  align-items: center;
}


.department-card {
  background: #194995;
  width: 180px;
  height: 180px;
  border-radius: 16px;
  padding: 20px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.department-card:hover {
  transform: translateY(-8px);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
}


.department-icon {
  font-size: 24px;
  margin-bottom: 10px;
}

.department-name {
  color: #ffffff;
}
.family-info-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px 0;
  font-size: 1.2rem; /* Increases the text size */
  color: black; /* Ensures text is black */
}

.family-info-row p {
  flex: 1 1 50%; /* Makes it responsive in two columns */
  margin: 5px 0;
  font-weight: 500;
}

@media (max-width: 768px) {
  .family-info-row p {
    flex: 1 1 100%; /* Stacks the info on smaller screens */
  }
}